<template>
  <div class="content_box">
    <div v-show="!$route.meta.status" class="content">
      <div class="title">
        <h1 class="login_name">
          <span class="account">您现在使用的账号姓名是：</span>
          <span class="account_name">{{ username }}</span>
        </h1>
        <h1 class="colleague">
          <span>系统已为您分配考核的同事有：</span>
          <p>
            <span v-for="(v, i) in systemAssign" :key="i">{{ v.employee_realname }}<span v-show="i!==systemAssign.length-1">、</span></span>
          </p>
          <span>您还要从下面选择<span class="number">{{ showNum }}</span>位同事进行考核，请选择：</span>
        </h1>
      </div>
      <div class="name_content">
        <el-checkbox-group v-model="checkList" @change="changeStaffList">
          <el-checkbox v-for="(v, i) in staffList[staffIndex]" :key="i" :disabled="isdis" :label="v">{{
            v.employee_realname
          }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div v-if="obsolete" class="btn_box">
        <el-button type="danger" @click="resetStaff">重新选</el-button>
        <el-button type="danger" @click="changeStaff">换一换</el-button>
        <el-button type="danger" @click="choose">选好了</el-button>
      </div>
    </div>
    <keep-alive>
      <router-view v-show="!$route.meta.hidden"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { getCulture, toChoseApi } from '@/api/assessment'
export default {
  data() {
    return {
      checkList: [],
      num: 0,
      showNum: 0,
      setting: [],
      username: '',
      staffList: [],
      staffIndex: 0,
      systemAssign: [],
      exam_date: '',
      isdis: false,
      obsolete: true,
      last_list: []
    }
  },
  watch: {
    '$route'(to, from) {
      this.showNum = this.num
      this.checkList = []
      this.isdis = false
      if (this.$route.query.isAgain == 1) {
        document.title = sessionStorage.getItem('name_zl') + '-企业文化考核'
        this.exam_date = this.$route.query.exam_date
        sessionStorage.setItem('DATE', this.exam_date)
        this.getData()
      }
    },
    checkList(newV, oldV) {
      if (newV.length > this.num) {
        this.showNum = 0
        this.checkList.pop()
      }
    }
  },
  created() {
    document.title = sessionStorage.getItem('name_zl') + '-企业文化考核'
    if (this.$route.path == '/assessment') {
      this.exam_date = this.$route.query.exam_date
      sessionStorage.setItem('DATE', this.exam_date)
      this.getData()
    }
  },
  methods: {
    // 选中人改变
    changeStaffList(val) {
      this.showNum = this.num - val.length
      if (this.showNum === 0) {
        this.isdis = true
      }
    },
    // 重新选
    resetStaff() {
      this.showNum = this.num
      this.checkList = []
      this.isdis = false
    },
    // 换一换
    changeStaff() {
      if (this.staffIndex >= this.staffList.length - 1) {
        this.staffIndex = 0
        return
      }
      this.staffIndex++
    },
    // 选好了
    choose() {
      if (!this.last_list.length) {
        if (this.checkList.length !== this.num) {
          this.$message.error(`请选择其中${this.num}位同事`)
          return
        }
      }
      const params = {
        exam_date: this.exam_date,
        name: 'answer',
        page: 1,
        selected: JSON.stringify(this.checkList)
      }
      toChoseApi(params).then((res) => {
        if (res.errNo === 0) {
          sessionStorage.setItem('staffList', JSON.stringify(res.result))
          sessionStorage.setItem('isAgain', 1)
          this.$router.push({ path: '/assessment/cultrue', query: { exam_date: this.exam_date, num: this.num }})
        }
      })
    },
    getData() {
      const params = {
        exam_date: this.exam_date
      }
      getCulture(params).then((res) => {
        if (res.errNo === 0) {
          this.num = res.result.num
          this.showNum = res.result.num
          this.setting = res.result.setting
          this.username = res.result.user_name
          this.staffList = res.result.staff_list
          this.systemAssign = res.result.systemAssign
          sessionStorage.setItem('setting', JSON.stringify(res.result.setting))
          if (!this.num) {
            this.choose()
          }
          if (res.result.last_list) {
            this.last_list = res.result.last_list
            this.checkList = res.result.last_list
            if (this.$route.query.isAgain != 1) {
              this.choose()
            } else if (this.$route.query.isAgain == 1) {
              this.checkList = []
            }
          }
          this.staffList.forEach(item => {
            item.forEach(items => {
              if (items.case == 1) {
                this.checkList.push(items)
              }
            })
          })
          this.showNum = this.showNum - this.checkList.length
        } else if (res.errNo === 1001) {
          this.$message.error('缺少评测时间')
        } else if (res.errNo === 1002) {
          this.obsolete = false
          alert('已过评测最后时间')
        } else if (res.errNo === 1003) {
          this.$message.error('仅限内部员工评测')
        } else if (res.errNo === 1004) {
          console.log('本人已答过当月文化考核')
          this.$router.push({ path: '/assessment/assuccess' })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  text-align: left;
  font-size: 24px;
  .account_name {
    color: #f00;
  }
}
.colleague {
  p {
    text-indent: 2em;
  }
}
.number {
  color: #f00;
}
.content_box {
  background-color: #fff;
  width: 100%;
  padding: 30px 0;
  margin-top: 60px;
  .content {
    width: 90%;
    margin: 0 auto;
    border: 1px solid #ccc;
    padding: 30px 50px;
    .name_content {
      border-top: 1px solid #ccc;
      padding-top: 10px;
      margin-top: 20px;
    }
  }
}
.btn_box {
  margin-top: 20px;
}
/deep/ .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
/deep/ .el-checkbox {
  width: 16%;
  text-align: left;
  margin-right: 0;
  line-height: 50px;
}
/deep/ .el-button {
  margin-left: 40px;
}
</style>
